import {
  CskhHeader,
  HistoryMenu,
  HomeHeader,
  MeMenu,
  SportsSoccer,
  TicketIcon,
  XpayMenu,
} from "assets/icons";
import classNames from "classnames";
import Loader from "components/Loader";
import ChangeLanguage from "Page/ChangeLanguage";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { FINET_HOST } from "../../constants/url";
import { routes } from "./../../App";
import { Notifications } from "assets/icons";

function LayoutPage(props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { token, isUserLoggedIn } = useSelector((state) =>
    state.member ? state.member : {}
  );

  const { isAuth = false, Component, className = "" } = props;
  const { location, history, isHeader = true, isFooter = true } = props;
  const { pathname } = location;
  const isMatch = useMemo(() => {
    return pathname === routes.match.path;
  }, [pathname]);

  const isManagementWallet = useMemo(() => {
    return pathname === routes.transaction.path;
  }, [pathname]);

  const isHistory = useMemo(() => {
    return pathname === routes.managementBetHistory.path;
  }, [pathname]);

  const isProfile = useMemo(() => {
    return pathname === routes.managementGroup.path;
  }, [pathname]);

  function handleClickFooterIcon(path) {
    history.push(path);
  }

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const headerId = document.getElementById("header-sticky");
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle("sticky");
        } else {
          headerId.classList.remove("sticky");
        }
      }
    });
  }, []);

  useEffect(() => {
    const bodyId = document.getElementById("body-root");

    if (bodyId && !bodyId.classList) {
      bodyId.className = "";
      bodyId.classList.add(className || "");
    }
  }, [className]);

  useEffect(() => {
    if (isAuth) {
      if (!isUserLoggedIn) {
        history.push("/login");
      }
    }
  }, [props, isAuth]);

  const PATHNAME = window.location.pathname;

  return (
    <>
      {isUserLoggedIn && isHeader && (
        <header id="" className="layout__header layout">
          <div className="logo-outer">
            <a href="/">
              <HomeHeader className="fs-1" />
            </a>
            <div
              className="icon-notification"
              onClick={() => history.push("/notifications")}
            >
              <Notifications />
            </div>
          </div>
          <div className="logo-wrapper">
            <img
              onClick={() => history.push("/")}
              className="logo-main"
              src={"/assets/images/logo.png"}
              alt="logo-main"
            />
          </div>
          <div className="support">
            <ChangeLanguage />
          </div>
        </header>
      )}
      <>
        <div className={`layout__content`}>
          <Component {...props} setIsLoading={setIsLoading} />
        </div>
      </>

      {isUserLoggedIn && isFooter && (
        <footer
          className="d-block p-0 fixed-bottom"
          style={{
            marginBottom: "-20px",
            margin: "auto",
          }}
        >
          <div
            className="d-flex justify-content-around py-2 px-1 navigation text-center mb-2"
            style={{ height: "60px" }}
          >
            <div
              className={classNames("", {
                "border-bottom-active": isMatch,
                "text-primary": isMatch,
                selected: PATHNAME === routes.match.path,
              })}
              role="button"
              onClick={() => handleClickFooterIcon(routes.match.path)}
            >
              {isMatch ? (
                <SportsSoccer className="fs-3" />
              ) : (
                <SportsSoccer className="fs-3" />
              )}
              <p>{intl.formatMessage({ id: routes.match.label })}</p>
            </div>
            <div
              className={classNames("", {
                "border-bottom-active": isManagementWallet,
                "text-primary": isManagementWallet,
                selected: PATHNAME === routes.transaction.path,
              })}
              role="button"
              onClick={() => handleClickFooterIcon(routes.transaction.path)}
            >
              {isManagementWallet ? (
                <TicketIcon className="fs-3" />
              ) : (
                <TicketIcon className="fs-3" />
              )}
              <p>{intl.formatMessage({ id: "wallet_footmenu" })}</p>
            </div>
            <div
              className={"relative"}
              role="button"
              onClick={() => handleClickFooterIcon(routes.menu.path)}
              style={{ width: "56px", height: "56px" }}
            >
              <div
                className="rounded-circle text-center"
                style={{ position: "absolute", top: "-25px" }}
              >
                <XpayMenu className="fs-1 icon-menu" />
                <p>{intl.formatMessage({ id: "main_button" })}</p>
              </div>
            </div>
            <div
              className={classNames("", {
                "border-bottom-active": isHistory,
                "text-primary": isHistory,
                selected: PATHNAME === routes.managementBetHistory.path,
              })}
              role="button"
              onClick={() =>
                handleClickFooterIcon(routes.managementBetHistory.path)
              }
            >
              {isHistory ? (
                <HistoryMenu className="fs-3" />
              ) : (
                <HistoryMenu className="fs-3" />
              )}
              <p>{intl.formatMessage({ id: "history" })}</p>
            </div>
            <div
              className={classNames("", {
                "border-bottom-active": isProfile,
                "text-primary": isProfile,
                selected: PATHNAME === routes.profile.path,
              })}
              role="button"
              onClick={() => handleClickFooterIcon(routes.profile.path)}
            >
              <MeMenu className="fs-3" />
              <p>{intl.formatMessage({ id: routes.profile.label })}</p>
            </div>
          </div>
        </footer>
      )}
      {isLoading ? <Loader /> : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {},
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
